<template>
  <tr
    v-if ="player"
    :class="`completeness-${player.completeness}`"
  >
  <td
    v-for="(p, index) in profile_match_headers"
    :key="`pm${index}`"
  >
    {{ player.profile_matches && player.profile_matches[p.id] ? player.profile_matches[p.id] : "-" | percentage }}
  </td>
    <td id="report">
      <div
        class="dropdown"
        v-if="player.reportState &&
          player.reportState !== 'error' &&
          player.reportState !== 'not-ready'"
      >
        <b-dropdown
          class="mx-2 my-1"
          split
          split-variant="primary"
          size="sm"
          variant="outline-primary"
          @click="goToReport(0)"
        >
          <template slot="button-content">
            <span id="report-button">
              {{ $t('gameplay-table.table_row.report_column.report_button') }}
            </span>
          </template>
          <b-dropdown-item
            v-for="(link, index) in player.links"
            :key="index"
            :href="getReport(index)"
            target="_blank"
            rel="noopener noreferrer"
            @click="dropdownReportClicked(link)"
          >
            {{ getReportName(link) }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </td>
    <td class="warnings">
      <v-popover
        trigger="manual"
        placement="bottom"
        offset="10"
        :offset-overflow="true"
        :open="showWarnings"
        :delay="{ show: 500 }"
        :autoHide="false"
        :popoverClass="`popover-${reportsiteLink} warning`"
      >
        <div
          v-if="player.reportState && player.reportState!== 'ok'"
          :id="`user-warning${player.gameplayId}`"
          :class="`report-${player.reportState} warning-message tooltip-target`"
          v-touch="onTouchStart"
          @mouseover="onShow"
          @mouseleave="hideTooltip"
        >
          <img
            :class="`warning-icon`"
            :src="`/img/warning-icon-${player.reportState}.svg`"
          >
        </div>
        <template
          v-if="showWarnings"
          :class="`report-${player.reportState} tooltiptext`"
          slot="popover"
        >
          <div
            v-if="typeof userWarnings === 'object'"
            :class="`report-${player.reportState}`"
          >
            <p id="warning-message">
              {{ $t(`gameplay-table.search-input-placeholders.report.${player.reportState}`) }}
            </p>
            <p
              v-for="(warning, index) in userWarnings"
              :key="index"
            >
              <span v-if="warning.game_name">
                {{ warning.game_name }}:
              </span>

              {{ warning.message }}
            </p>
          </div>
          <div
            v-else
            :class="`report-${player.reportState}`"
          >
            <p>
              {{ $t(`gameplay-table.search-input-placeholders.report.${player.reportState}`) }}
            </p>
            <p>
              {{ userWarnings }}
            </p>
          </div>
        </template>
      </v-popover>
    </td>
    <td v-if="player.videos">
      <div class="videos">
        <i v-for="(video, index) in player.videos"
          :key="`video_${index}`"
          class="fas fa-play-circle"
          :class="video.url ? 'uploaded' : ''"
          :title="video.question"
          @click="openVideoModal(video)">
        </i>
        <b-modal
          v-if="selectedVideo"
          id="assessment-video-modal"
          size="lg"
          v-model="showVideoModal"
          :title="selectedVideo.question"
          hide-footer
        >
        <video controls>
        <source :src="selectedVideo.url" type="video/mp4">
        <a :href="selectedVideo.url">{{$t('download_video')}}</a>
      </video>
      </b-modal>
    </div>
    </td>
    <td v-show="!anonym_project">
      {{ player.name || '' }}
    </td>
    <td v-show="anonym_project">
      {{ player.external_id || '' }}
    </td>
    <td v-show="!anonym_project">
      {{ player.email }}
    </td>
    <td>
      {{ player.start_time | moment }}
    </td>
    <td
      v-for="field in custom_fields"
      :key="field.export_key"
    >
      <div v-if="Array.isArray(player.custom_fields[field.export_key])">
        <ul>
          <li
            v-for="item in player.custom_fields[field.export_key]"
            :key="item"
          >
            {{ item }}
          </li>
        </ul>
      </div>
      <span v-else>
        {{ setFieldValue(player.custom_fields[field.export_key]) }}
      </span>
    </td>
    <td
      v-for="(c, index) in competence_fields"
      :key="index"
    >
      {{ player.competencies && player.competencies[c] ? player.competencies[c] : "-" }}
    </td>
  </tr>
</template>

<script>

import { mapState } from 'vuex';
import filters from '../common/filters';
import MatomoMixin from '../common/matomoMixin';

export default {
  mixins: [
    MatomoMixin,
    filters,
  ],
  props: {
    player: {
      required: true,
    },
    anonym_project: {
      required: true,
    },
    custom_fields: {
      required: true,
    },
    competence_fields: {
      required: true,
    },
    profile_match_headers: {
      required: true,
    },
  },
  data() {
    return {
      userWarnings: null,
      showWarnings: false,
      selectedVideo: null,
      showVideoModal: false,
    };
  },
  computed: {
    ...mapState('user', ['user_lang']),
    reportsiteLink() {
      return (this.$route.params.aggregatorId) ? `/${this.$route.params.aggregatorId}/${this.player.uuid}`
        : `/${this.player.gameplayId}`;
    },
  },
  methods: {
    openVideoModal(video) {
      this.selectedVideo = video;
      if (this.selectedVideo && this.selectedVideo.url) {
        this.selectedVideo.question = video.question;
        this.showVideoModal = true;
        this.logToMatomo();
      }
    },
    logToMatomo() {
      const { url } = this.selectedVideo;
      const videoFilename = url.substring(url.lastIndexOf('/') + 1);
      this.trackMatomoEvent('Video viewed', `A-${this.$route.params.aggregatorId}, P-${this.player.id}`, `file: ${videoFilename}`);
    },
    onTouchStart(e) {
      e.preventDefault();
      this.handleClick();
    },
    onShow() {
      if (!this.showWarnings) {
        this.userWarnings = this.player.report_warnings;
        this.showTooltip();
      }
    },
    showTooltip() {
      this.showWarnings = true;
      setTimeout(this.addEventListenerToTooltip, 500);
    },
    hideTooltip() {
      this.showWarnings = false;
    },
    handleClick() {
      if (!this.showWarnings) {
        this.onShow();
      } else {
        this.hideTooltip();
      }
    },
    addEventListenerToTooltip() {
      const openedTooltip = document.getElementsByClassName(`popover-${this.reportsiteLink}`)[0];
      if (openedTooltip && openedTooltip.classList.contains('open')) {
        // click event listener is added for small screen navigation
        openedTooltip.addEventListener('click', this.handleClick);
      }
    },
    getReport(linkIndex) {
      return this.player.links[linkIndex][1];
    },
    goToReport(linkIndex) {
      this.trackMatomoEvent('Report', 'Open main report', 'main');
      const url = this.player.links[linkIndex][1];
      window.open(url);
    },
    dropdownReportClicked(link) {
      this.trackMatomoEvent('Report', 'Open dropdown report', link[0]);
    },
    setFieldValue(value) {
      if (value === true) {
        return this.$t(
          'gameplay-table.search-input-placeholders.custom_checkbox.checked',
        );
      } if (value === false) {
        return this.$t(
          'gameplay-table.search-input-placeholders.custom_checkbox.not_checked',
        );
      } if (value === null) {
        return '-';
      }
      return value;
    },
    getReportName(link) {
      // return translation if available, or the name provided by the api
      // this condition overrides i18n rule of returning key when no translation is available
      return this.$t(`gameplay-table.table_row.report_column.${link[0]}`)
        !== `gameplay-table.table_row.report_column.${link[0]}`
        ? this.$t(`gameplay-table.table_row.report_column.${link[0]}`)
        : link[0];
    },
  },
  filters: {
    percentage(value) {
      if (!value || value === '-') return '-';
      value = Math.round((value / 1) * 100 * 10) / 10;
      return `${value}%`;
    },
  },
  created() {
    window.addEventListener('scroll', this.hideTooltip);
  },
};
</script>

<style lang="scss" scoped>
.btn-primary,
.btn-primary:hover {
  background-color: #4e73df;
}
.report-error {
  color: #ed5464;
}
.report-warning {
  color: #f2c80f;
}
.report-not-ready {
  color: #a245ff;
}

.table td, .table th {
  padding: 0.25rem;
  vertical-align: middle;
}

@media screen and (max-width: 900px) {
  .table td, .table th {
    padding: 0.2rem;
  }
  .warning-icon {
    width: 24px;
    height: 24px;
  }
}

@media screen and (max-width: 500px) {
  .table td, .table th {
    padding: 0.1rem;
  }
  .warning-icon {
    width: 20px;
    height: 20px;
  }
}

.warning-message {
  position: relative;
  display: inline-block;
}

.warning-message .tooltiptext {
  width: 120px;
  color: inherit;
  text-align: left;
  border-radius: 6px;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: .3rem;
  padding: .5rem .75rem;
  background-color: white;
  width: 300px;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -150px;
  margin-top: 3px;
}

.tooltiptext div p:last-of-type {
  margin-bottom: 0px;
}

td .videos {
  display: flex;
  width: 100px;
  flex-wrap: wrap;
  i {
    height: 28px;
    width: 28px;
    font-size: 28px;
    font-weight: unset;
    &.uploaded {
      color: #4e73df;
      cursor: pointer;
    }
  }
}

#assessment-video-modal video {
  width: 100%;
}
</style>
